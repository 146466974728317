import React, { Component } from 'react';

import firebase from "../js/firebase";
import swal from 'sweetalert2'

class FormLogin extends Component {


constructor(props) {
  super(props);
  this.state = {
    email: "",
    password: "",
    //////estado del usuario
    user: null,
  };
}

componentDidMount(){
  console.log(this)
  var parent = this;
   firebase.auth().onAuthStateChanged(function(user) {
    parent.setState({
      user: user
    })
  });
}

  login = () => {
    firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(res => {
        this.props.history.push("/client")
      })
      .catch(error => {
        console.log(error)
        if( error.code === "auth/user-not-found" ){
          swal.fire({
            title: 'Error',
            text: 'Email not found',
            type: 'error',
            confirmButtonText: 'Cool'
          })

        }
        if( error.code === "auth/invalid-email" ){
          swal.fire({
            title: 'Error',
            text: 'Invalid Email',
            type: 'error',
            confirmButtonText: 'Cool'
          })
        }
        if( error.code === "auth/wrong-password" ){
          swal.fire({
            title: 'Error',
            text: 'Incorrect password',
            type: 'error',
            confirmButtonText: 'Cool'
          })
        }
      })
    }

render() {

  return (
    <div>
      <div className="r-auth-form">

        <form action="#" onSubmit={e => e.preventDefault()}>
          <div className="form-group">
            <input className="form-control" onChange = {event => this.setState({email: event.target.value})}  type="email" placeholder="Email"/>
          </div>
          <div className="form-group">
            <input className="form-control" onChange={event => this.setState({password: event.target.value})} type="password" placeholder="Password"/>
          </div>


          <div className="form-group">

              <button className="btn btn-full" onClick={this.login} >LOGIN NOW</button>

          </div>



        </form>
        <div className="r-from-inof">
          <p className="text-center">
            * Denotes mandatory field. <br/>
            ** At least one telephone number is required.
          </p>
        </div>
      </div>
    </div>

  )
}
}
export default FormLogin;

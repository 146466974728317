import React, { Component } from 'react';

import aboutImg1 from '../assets/images/about-img-01.png';
import userImg01 from '../assets/images/user-01.png';
import userImg02 from '../assets/images/user-02.png';
import userImg03 from '../assets/images/user-03.png';
class AboutUsPage extends Component {

render() {
  return (
    <div>
    <section id="about">
      <div className="r-about-info">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="r-about-info-img">
                <img src={aboutImg1} className="img-fluid d-block m-auto" alt=""/>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="r-about-info-content">
                <div className="r-sec-head r-sec-head-left r-sec-head-line r-sec-head-w pt-0">
                  <span>KEY FACTORS</span>
                  <h2>Why <b>Global Insurance</b></h2>
                </div>
                <p>
                  Founded by normal people who just want to insurance their car for a couple of days. No longer need to buy the whole month.
                </p>
                <ul className="mb-0 pl-0">
                  <li><i className="fas fa-user"/>Working strong since 2012 with over 4.000 customers</li>
                  <li><i className="fas fa-user"/>We insurance all brand & type cars in our garage</li>
                  <li><i className="fas fa-user"/>Buy only what you need when you need it</li>
                  <li><i className="fas fa-user"/>Reporting claims is just as easy</li>
                  <li><i className="fas fa-user"/>Best customer service</li>
                  <li><i className="fas fa-user"/>100% Satisfaction Guaranteed</li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="r-quote">
      <div className="r-quote r-quote-sub pt-0 r-quote-noafter">
        <div className="r-sec-head r-accent-color r-sec-head-t">
          <span>LATEST TESTIMONIAL</span>
          <h2>What <b>They Say</b> About Us.</h2>
        </div>
        <div className="container">
          <div className="row">
              <div className="col-md-12" data-wow-delay="0.2s">
                  <div id="r-quote-carousel" className="carousel slide">
                    <div className="carousel-inner" role="listbox">
                      <div className="carousel-item">
                         <p>"...I want to thank Global Insurance for helping me save over $400 per year and still keep the same coverage!"</p>
                      </div>
                      <div className="carousel-item active">
                        <p>“This was our first time using a short-term insurance and I am very pleased with the whole experience. Your price was lower than other companies.”</p>
                      </div>
                      <div className="carousel-item">
                         <p>“A friend recommended me Global Insurance and I can't thank him enough. From start to finish, best experience ever, I highly recommend it”</p>
                      </div>
                    </div>
                    <ol className="carousel-indicators">
                      <li data-target="#r-quote-carousel" data-slide-to="0">
                        <img className="img-fluid d-block" src={userImg02} alt=""/>
                        <span>
                          <b>Roberto Garcia</b><br/>
                          Texas
                        </span>
                      </li>
                      <li data-target="#r-quote-carousel" data-slide-to="1" className="active text-center">
                        <img className="img-fluid d-block" src={userImg01} alt=""/>
                        <span>
                          <b>Ariana Contreras</b><br/>
                          North Carolina
                        </span>
                      </li>
                      <li data-target="#r-quote-carousel" data-slide-to="2">
                       <img className="img-fluid d-block" src={userImg03} alt=""/>
                       <span>
                          <b>Brad Smith</b><br/>
                          California
                       </span>
                      </li>
                    </ol>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}
}
export default AboutUsPage;

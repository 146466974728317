import React, { Component } from 'react';

import advantageIcon1 from '../assets/images/advantage-icon-1.png';
import advantageIcon2 from '../assets/images/advantage-icon-2.png';
import advantageIcon3 from '../assets/images/advantage-icon-3.png';


class HowItWorksPage extends Component {

render() {
  return (
    <div>
    <section  id="how" className="r-advantages-part">
      <div className="r-advantage-main-part">
        <div className="container clearfix">
          <div className="advantage-head">
            <span>3 SIMPLE  STEPS</span>
            <h2><b>How it Works</b></h2>
          </div>
          <div className="row clearfix">
             <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
               <div className="r-advantages-box">
                 <div className="icon"> <img src={advantageIcon1} alt=""/> </div>
                 <div className="head animated pulse">QUOTE</div>
                 <div className="sub-text">Insert your car and select the dates</div>
               </div>
             </div>
             <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
               <div className="r-advantages-box">
                 <div className="icon"> <img src={advantageIcon2}  alt=""/> </div>
                 <div className="head animated pulse">PAY ONLINE</div>
                 <div className="sub-text">Make a payment, we accept mayor credit cards</div>
               </div>
             </div>
             <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
               <div className="r-advantages-box">
                 <div className="icon"> <img src={advantageIcon3}  alt=""/> </div>
                 <div className="head animated pulse">INSURANCE</div>
                 <div className="sub-text">Receive your insurance policy <br/> via e-mail</div>
               </div>
             </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}
}
export default HowItWorksPage;

import React, { Component } from 'react';

import firebase from "../js/firebase";
import swal from 'sweetalert2'
import Moment from 'moment';

class FormIniRegister extends Component {

  constructor(props) {
    super(props);
    this.state = {

      email: "",
      password: "",
      passwordConf: "",
      nameClient:'',

      typePhoneClient:'',
      phoneClient:'',

      rfcClient:'',
      sexClient:'',
      dobClient:'',

      streetClient:'',
      numberClient:'',

      colonyClient:'',
      cityClient:'',
      townClient:'',

      stateClient:'',
      zipCodeClient:'',
      countryClient:'',
      notesClient:'',
      //////estado del usuario
      user: null,
    }
    this.changeManagement = this.changeManagement.bind(this);
  }


  changeManagement(event) {
      const name = event.target.name;
      this.setState({
        [name]: event.target.value
      });
    }

  //console.log(console.log({firebase.auth().currentUser.email}))

  componentDidMount(){
    var parent = this;
     firebase.auth().onAuthStateChanged(function(user) {
      parent.setState({
        user: user
      })
    });
  }

  signUp = () => {
  if( this.state.passwordConf ===  this.state.password){
  firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
    .then(user => {
      ///// jalar los datos
      var idUser = user.user.uid;
      var emailUser = user.user.email;

      //// cadena a guardar
      firebase.firestore().collection("clients").doc(idUser)
      .set({
          //string
          type: this.state.typePhoneClient,
          //string
          phone: this.state.phoneClient,
          //timestamp
          timestamp: Moment().toDate(),
          //string
          id: idUser,
          //string
          name: this.state.nameClient,
          //string
          email: emailUser,
          //string
          thumb: 'https://firebasestorage.googleapis.com/v0/b/global-insurance-llc.appspot.com/o/public%2Fuser-transparent.png?alt=media&token=08322061-d2d5-423a-9bde-e4abaed22a06',

          //string
          rfc: this.state.rfcClient,
          //string
          gender: this.state.sexClient,
          //string
          dob: this.state.dobClient,

          //string
          street: this.state.streetClient,
          //string
          number: this.state.numberClient,

          //string
          city: this.state.colonyClient,
          //string
          colony: this.state.cityClient,
          //string
          town: this.state.townClient,

          //string
          state: this.state.stateClient,
          //string
          zip: this.state.zipCodeClient,
          //string
          country: this.state.countryClient,
          //string
          notes: this.state.notesClient,
        })
        .then(function(res) {
          this.props.history.push("/client")
        })
        //// Mensaje de aprovacion limpiar formulario
        .then(function(docRef) {

      //console.log(docRef);
        })
        .catch(function(error) {
      //console.error("Error adding document: ", error);
        });
        this.props.history.push("/client")

    })

    .catch(error => {
      console.log(error)
      if( error.code === "auth/invalid-email" ){
        swal.fire({
          title: 'Error',
          text: 'Invalid Email',
          type: 'error',
          confirmButtonText: 'Cool'
        })
      }
      if( error.code === "auth/email-already-in-use" ){
        swal.fire({
          title: 'Error',
          text: 'The email address is already in use',
          type: 'error',
          confirmButtonText: 'Cool'
        })
      }
      if( error.code === "auth/weak-password" ){
        swal.fire({
          title: 'Error',
          text: 'The password must be at least 6 characters',
          type: 'error',
          confirmButtonText: 'Cool'
        })
      }
    })
    }
    else{
      swal.fire({
        title: 'Error',
        text: 'Password does not match when confirming',
        type: 'error',
        confirmButtonText: 'Cool'
      })
    }
  }



render() {
  return (
    <div>
      <div className="r-auth-form">
        <form action="#" onSubmit={e => e.preventDefault()}>
          <div className="form-group">
            <input name="nameClient" required className="form-control" placeholder="Full name" type="text" value={this.state.nameClient} onChange={this.changeManagement}/>
          </div>
          <div className="form-group">
            <input name="phoneClient" required className="form-control" placeholder="Phone" type="tel" value={this.state.phoneClient} onChange={this.changeManagement}/>
          </div>
          <div className="form-group">
            <input type="email" required className="form-control" onChange = {event => this.setState({email: event.target.value})} placeholder="Email Address"/>
          </div>
          <div className="form-group">
            <input type="password" required className="form-control" onChange={event => this.setState({passwordConf: event.target.value})} placeholder="Password"/>
          </div>
          <div className="form-group">
            <input type="password" required className="form-control" onChange={event => this.setState({password: event.target.value})} placeholder="Confirm Password"/>
          </div>

          {/*<div class="container">
            <div class="row">
              <div class="col">
                <div className="row row-inputs">
                  <div className="col-sm-12">
                      <div className="form-group">
                          <select name="typePhoneClient" placeholder="Type" className="form-control" value={this.state.typePhoneClient} onChange={this.changeManagement}>
                            <option value="" disabled selected>Type</option>
                            <option value="Mobile">Mobile</option>
                            <option value="Home">Home</option>
                            <option value="Work">Work</option>
                            <option value="Office">Office</option>
                          </select>
                          <span className="form-control-icon"><i className="fa fa-map-marker"></i></span>
                      </div>
                  </div>
                </div>
              </div>
              <div class="col-8">
                <div className="col-sm-15">
                  <div className="form-group">
                    <input name="phoneClient" required className="form-control" placeholder="Phone" type="tel" value={this.state.phoneClient} onChange={this.changeManagement}/>
                  </div>
                </div>
              </div>
            </div>
          </div>*/}

          <div className="form-group">
            <button className="btn btn-full" onClick={this.signUp} >SIGN UP NOW</button>
          </div>
        </form>
      </div>
    </div>

  )
}
}
export default FormIniRegister;

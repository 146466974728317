import React from 'react';
import "./js/firebase";
import { BrowserRouter as Router, Route } from "react-router-dom";//import React from "react";
//<!-- CSS:: DATEPICKER -->
import './assets/css/plugins/datepicker/datepicker.css';
//<!-- CSS:: ANIMATE -->
import './assets/css/plugins/animate/animate.css';
//<!-- CSS:: MAIN -->
import './assets/css/main.css';
import './assets/color-files/color-08.css';
///modules
import LandingPage from './modules/LandingPage';
import LoginRegisterPage from './modules/LoginRegisterPage';
import ClientPage from './modules/ClientPage';
import InsuranceQuotePage from './modules/InsuranceQuotePage';
import InsuranceSuccessPage from './modules/InsuranceSuccessPage';
import BuyInsuranceNow from './modules/BuyInsuranceNow';
import Terms from "./modules/Terms";
import Privacy from "./modules/Privacy";


function App() {
  return (
    <div>
      <Router>
        <Route path="/" exact render={props => <LandingPage {...props}  />} />
        <Route path="/login" exact render={props => <LoginRegisterPage {...props}  />} />
        <Route path="/client" exact render={props => <ClientPage {...props}  />} />
        <Route path="/insurance" exact render={props => <InsuranceQuotePage {...props}  />} />
        <Route path="/insuranceSuccess" exact render={props => <InsuranceSuccessPage {...props}  />} />
        <Route path="/buyNow" exact render={props => <BuyInsuranceNow {...props}  />} />
        <Route path="/terms" exact render={props => <Terms {...props}  />} />
        <Route path="/privacy" exact render={props => <Privacy {...props} />} />
      </Router>
    </div>
  );
}

export default App;

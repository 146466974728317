import React, { Component } from 'react';

import advantageIcon7 from '../assets/images/advantage-icon-7.png';
import FooterShortPage from './FooterPage';
import HeaderShortPage from './HeaderShortPage';

import FormIniRegister from '../forms/FormIniRegister';

import firebase from "../js/firebase";

class InsuranceSuccessPage extends Component {


  constructor(props) {
    super(props);
    this.state = {
      //////estado del usuario
      user: null,
      clientName: null,
      clientUID: null,

    };
  }
  componentDidMount(){
    var parent = this;
     firebase.auth().onAuthStateChanged(function(user) {
      parent.setState({
        user: user,
      })
    });


        ////usuario Cliente datos
         firebase.auth().onAuthStateChanged((user) => {
           console.log(user)
          if (user) {
              if(user.email){
              var uid = user.uid;
              firebase.firestore().doc("clients/" + uid).get().then((documentSnapshot) => {
                let data = documentSnapshot.data();
                this.setState({clientUID: data.id})
                this.setState({clientName: data.name})
              });
            }
            else{
              console.log(user.uid)
            }
          }
        });

  }
  gopanel = () => {
    this.props.history.push("/client")
  }


  render() {
    return (
      <div className="r-wrapper">
        <HeaderShortPage/>

        <section id="r-login-register">
          <div className="r-login-register">

            <div className="r-login-register-in">
              <div className="r-auth-or">
                <span>GO</span>
              </div>
              <div className="clearfix">
                <div className="r-auth-outer r-login">
                  <div className="r-sec-head r-accent-color">
                      <div className="head animated pulse">Your payment was successful</div>
                      <div className="icon"> <img src={advantageIcon7}  alt=""/> </div>
                      <div className="sub-text">Your policy was sent to your email</div>
                  </div>
                </div>
                  { !this.state.clientName ?
                   <div className="r-auth-outer r-register">
                     <div className="r-auth-head">
                       <h4><b>Register</b> Now</h4>
                       <span>Required information for account creation</span>
                       <h4>generate <b>a police</b> histoy</h4>
                     </div>
                     <FormIniRegister {...this.props}/>
                   </div>
                  :
                  <div className="r-auth-outer r-register">
                    <div className="r-sec-head r-accent-color">
                      <h5>Go to <b>your panel </b></h5>
                      <span>and check your movements</span>
                    </div>
                    <div className="form-group">
                      <button className="btn btn-full" onClick={this.gopanel} >GO PANEL</button>
                    </div>
                  </div>
                  }
              </div>
            </div>
          </div>
        </section>

        <FooterShortPage/>
      </div>
    )
  }
}
export default InsuranceSuccessPage;

import React, { Component } from 'react';

import HeaderPage from './HeaderPage';
import HowItWorksPage from './HowItWorksPage';
import AboutUsPage from './AboutUsPage';
import InsurancePage from './InsurancePage';
// import FAQPage from './FAQPage';
import ContactPage from './ContactPage';
import FooterPage from './FooterPage';



class LandingPage extends Component {

componentDidMount() {
  window.App.DefaultHomeCarousel();
}

render() {
  return (
    <div>
    <HeaderPage{...this.props}/>
    <HowItWorksPage{...this.props}/>
    <AboutUsPage{...this.props}/>
    <InsurancePage{...this.props}/>
    {/*<FAQPage/>*/}
    <ContactPage{...this.props}/>
    <FooterPage{...this.props}/>
    </div>
  )
}
}
export default LandingPage;

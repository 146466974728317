import React from "react";
import FooterShortPage from "./FooterPage";
import HeaderShortPage from "./HeaderShortPage";

const Privacy = () => {
	return (
			<div className="r-wrapper">
				<HeaderShortPage/>
				<section id="terms">
					<div className="r-login-register">
						<div className="container clearfix">
							<div className="row clearfix">
								<div className="r-auth-head">
									<h2><b>Global Insurance Llc </b>PRIVACY POLICY</h2>
									<br/>
								</div>

								<div className="row row-inputs">
									<div className="col-sm-12">
										<div className="form-group has-icon has-label">
                                            <label>YOUR PRIVACY IS OUR BUSINESS</label>
                                            <label>Our Privacy Policy will be presented to you at the time of purchase and is available to you at any time you may need it.</label>
                                            <br />
                                            All of your information shall and will remain private at all times.  This Notice explains in detail how we gather, use, protect and, if necessary, share customer information.  We define customer information as all nonpublic information related to an identified individual, which is referred as personally identifiable information.
                                            <br />
                                            <br />
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</section>
				<FooterShortPage/>
			</div>
		);
};

export default Privacy;

import React, { Component } from 'react';

import insuredImg from '../assets/images/insured.jpg';

class InsurancePage extends Component {

render() {
  return (
    <div>
    <section id="insurance">
        <div className="r-about-info">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="r-about-info-img">
                  <img src={insuredImg} className="img-fluid d-block m-auto" alt=""/>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="r-about-info-content">
                  <div className="r-sec-head r-sec-head-left r-sec-head-line r-sec-head-w pt-0">
                    <span>KNOW MORE ABOUT US</span>
                    <h2>Our <b>Insurance</b> Policies</h2>
                  </div>
                  <p>
                   Global Insurance provides car insurances when you’re driving temporarily in the United States <br/>
                   It’s illegal to drive without car insurance in most states, even short-term driving can result in fines or jail time if you get caught driving an uninsured car.
                  <br/><br/>
                  Additionally, you could be liable to pay for injuries and damage out of pocket, costs which may be extremely expensive for the average person.
                  <br/><br/>
                  <b>Never drive without insurance coverage</b>
                  </p>


                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
}
export default InsurancePage;

import React, { Component } from 'react';

import { Link } from "react-router-dom";

import FormLogin from '../forms/FormLogin';
import FormIniRegister from '../forms/FormIniRegister';
import FooterShortPage from './FooterPage';

import logoAlterno from '../assets/images/logo-alterno.png';
import iconHome from '../assets/images/icon-home.png';

import firebase from "../js/firebase";

class LoginRegisterPage extends Component {


  constructor(props) {
    super(props);
    this.state = {
      //////estado del usuario
      user: null,
    };
  }

  //console.log(console.log({firebase.auth().currentUser.email}))

  componentDidMount(){
    var parent = this;
     firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        if (user.login) {
          console.log(user.user)
          console.log(user.login)
          parent.props.history.push("/client");
          }
        }
    });
  }


render() {
  return (
    <div>
    <div className="r-wrapper">
      <header>
     <div className="r-header r-header-inner r-header-strip-01">
          <div className="r-header-strip">
            <div className="container">
              <div className="row clearfix">
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                  <div className="r-logo">
                    <Link to="/" className="d-inline-block">
                      <img src={logoAlterno} className="img-fluid d-block" alt=""/>
                    </Link>
                  </div>

                </div>
                <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                <div className="r-header-action float-right">
                  <Link to="/">
                    <img src={iconHome} alt=""/>
                    <span>HOME</span>
                  </Link>
                </div>
                 <div className="r-nav-section float-right">
                     <nav>
                      <ul>
                        {/*<li><Link to="/"><span>HOME</span></Link></li>
                        <li>
                          <a href="#how">HOW IT WORKS</a>
                        </li>
                        <li className="r-has-child">
                          <a href="#about">ABOUT US</a>
                        </li>
                        <li className="r-has-child">
                          <a href="#insurance">INSURANCE</a>
                        </li>
                        <li className="r-has-child">
                          <a href="#faq">FAQ</a>
                        </li>
                        <li>
                          <a href="#contact">CONTACT US</a>
                        </li>*/}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="r-header-inner-banner">
            <div className="r-header-in-over">
              <h1><b>Login or Register</b></h1>
              <div className="r-breadcrum">
                <ul>
                  <li><Link to="/"><span>HOME</span></Link></li>
                  <li><span>Login</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section id="r-login-register">
        <div className="r-login-register">
          <div className="r-login-register-in">
            <div className="r-auth-or">
              <span>OR</span>
            </div>
            <div className="clearfix">
              <div className="r-auth-outer r-login">
                <div className="r-auth-head">
                  <h2><b>Login</b> Now</h2>
                  <span>Login to our website</span>
                </div>
                <FormLogin {...this.props}/>
              </div>
              <div className="r-auth-outer r-register">
                <div className="r-auth-head">
                  <h2><b>Register</b> Now</h2>
                  <span>Required information for account creation</span>
                </div>
                <FormIniRegister {...this.props}/>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterShortPage/>
    </div>
    </div>
  )
}
}
export default LoginRegisterPage;

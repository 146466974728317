import React, { Component } from 'react';

import FooterShortPage from './FooterPage';
import HeaderShortPage from './HeaderShortPage';
import FormHeader from '../forms/FormHeader';

import firebase from "../js/firebase";

class BuyInsuranceNow extends Component {


  constructor(props) {
    super(props);
    this.state = {
      //////estado del usuario
      user: null,
    };
  }
  componentDidMount(){
    let parent = this;
     firebase.auth().onAuthStateChanged(function(user) {
      if(!user){
        parent.props.history.push("/login");
      }
    });
  }


render() {
  return (
    <div>
    <div className="r-wrapper">
    <HeaderShortPage/>
    <section  id="how" className="r-advantages-part">
        <div className="container clearfix">
          <div className="row clearfix">

             <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
             </div>

             <div className="col-xl-6 col-lg-4 col-md-6 col-sm-6 col-xs-12">
             <div className="form-footer">
               <div className="inner clearfix">
                   <div className="r-sec-head r-accent-color r-sec-head-L">
                       <FormHeader {...this.props}/>
                   </div>
               </div>
                </div>
             </div>

             <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
             </div>
          </div>
        </div>
    </section>

      <FooterShortPage/>
    </div>
    </div>
  )
}
}
export default BuyInsuranceNow;

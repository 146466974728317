import React, { Component } from 'react';
import { Link } from "react-router-dom";

import FormHeader from '../forms/FormHeader';

import logowhite from '../assets/images/logo-white.png';
import iconlock from '../assets/images/icon-lock.png';
import iconUser from '../assets/images/icon-user.png';
import slider01 from '../assets/images/main-slider-01.jpg';
import slider02 from '../assets/images/main-slider-02.jpg';

import firebase from "../js/firebase";

class HeaderPage extends Component {


  constructor(props) {
    super(props);
    this.state = {
      //////estado del usuario
      user: null,
      clientName:""
    };
  }
  componentDidMount(){
    var parent = this;
    firebase.auth().onAuthStateChanged((user) => {
     if (user) {
        if(user.email){
          var uid = user.uid;
          firebase.firestore().doc("clients/" + uid).get().then((documentSnapshot) => {
            let data = documentSnapshot.data();
          this.setState({clientName: data ? data.name : null})
          });
       }
       else {
         this.setState({user: null})
       }
     }
   });

   firebase.auth().onAuthStateChanged(function(user) {
     parent.setState({
       user: user
     })
   });
  }


render() {
  return (
    <div>
      <div className="r-wrapper">
      <header id="head">
        <div className="r-header r-header-inner r-header-strip-01">
          <div className="r-header-strip">
            <div className="container">
              <div className="row clearfix">
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <div className="r-logo">
                  <Link to="/" className="d-inline-block">
                    <img src={logowhite} className="img-fluid d-block" alt=""/>
                  </Link>
                  </div>
                </div>

                <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 pr-0">
                  <div className="col-xl-1 r-header-action float-right">

                      { !this.state.clientName ?
                        <Link to="/login">
                          <img src={iconlock} alt=""/>
                          <span>Login</span>
                        </Link>
                      :
                        <Link to="/client">
                        <img src={iconUser} alt=""/>
                        <span>{this.state.clientName}</span>
                        </Link>
                      }

                  </div>
                  <div className="r-nav-section float-right">
                     <nav>
                      <ul>
                        <li className="r-has-child">
                          <a href="#head">HOME</a>
                        </li>
                        <li>
                          <a href="#how">HOW IT WORKS</a>
                        </li>
                        <li className="r-has-child">
                          <a href="#about">ABOUT US</a>
                        </li>
                        <li className="r-has-child">
                          <a href="#insurance">INSURANCE</a>
                        </li>
                        {/*<li className="r-has-child">
                          <a href="#faq">FAQ</a>
                        </li>*/}
                        <li>
                          <a href="#contact">CONTACT US</a>
                        </li>
                        { !this.state.clientName ?
                          <li>
                          </li>
                        :
                          <li>
                            <a href="#head" onClick={() => firebase.auth().signOut()}>LOGOUT</a>
                          </li>
                        }
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="r-slider r-slider-02">
            <div className="r-slider owl-carousel" id="defaultHomeSlider">
              <div className="r-slider-item">
                <img src={slider01} className="img-fluid d-block m-auto" alt=""/>
              </div>
              <div className="r-slider-item">
                <img src={slider02} className="img-fluid d-block m-auto" alt=""/>
              </div>
            </div>
          </div>
          <div className="r-slider-serach form-search dark">
            <FormHeader {...this.props}/>
          </div>
        </div>
      </header>
      </div>
    </div>
  )
}
}
export default HeaderPage;

import React, { Component } from 'react';
import { Link } from "react-router-dom";

import logowhite from '../assets/images/logo-white.png';
import iconUser from '../assets/images/icon-user.png';
import iconHome from '../assets/images/icon-home.png';

import firebase from "../js/firebase";

class HeaderShortPage extends Component {


  constructor(props) {
    super(props);
    this.state = {
      //////estado del usuario
      user: null,
      clientName: null
    };
  }

  componentDidMount(){
    var parent = this;
    firebase.auth().onAuthStateChanged((user) => {
     if (user) {
        if(user.email){
          var uid = user.uid;
          firebase.firestore().doc("clients/" + uid).get().then((documentSnapshot) => {
            let data = documentSnapshot.data();

          this.setState({clientName: data.name})
          });
       }
       else {
         this.setState({clientName: null})
       }
     }
   });

     firebase.auth().onAuthStateChanged(function(user) {
      parent.setState({
        user: user
      })
    });
  }


render() {
  return (
      <div className="r-wrapper">
      <header>
        <div className="r-header r-header-inner r-header-strip-01">
          <div className="r-header-strip">
            <div className="container">
              <div className="row clearfix">
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                  <div className="r-logo">
                    <Link to="/" className="d-inline-block">
                      <img src={logowhite} className="img-fluid d-block" alt=""/>
                    </Link>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                  <div className="col-xl-1 r-header-action float-right">
                    { !this.state.clientName ?
                      <Link to="/">
                        <div>
                          <img src={iconHome} alt=""/>
                          <span>HOME</span>
                        </div>
                      </Link>
                      :
                      <Link to="/client">
                      <img src={iconUser} alt=""/>
                      <span>{this.state.clientName}</span>
                      </Link>
                    }
                  </div>
                  <div className="r-nav-section float-right">
                    <nav>
                      <ul>
                        <li>
                          <Link to="/">
                            HOME
                          </Link>
                        </li>

                        { !this.state.clientName ?
                          <li>
                            <Link to="/login">
                              LOGIN
                            </Link>
                          </li>
                        :

                          <li>
                            <a href="# " onClick={() => firebase.auth().signOut()}>LOGOUT</a>
                          </li>
                        }
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="r-header-inner-bannerInsurance"></div>
        </div>
      </header>
      </div>
  )
}
}
export default HeaderShortPage;

import React, { Component } from 'react';
import sweetalert from 'sweetalert2';
import axios from 'axios';

class FormContactUs extends Component {

  constructor(props){
    super(props);
    this.state = {
      name: null,
      email: null,
      subject: null,
      message: null
    }
  }

  sendEmail = (e) => {
    e.preventDefault();

    if (!this.state.name || !this.state.email || !this.state.subject  || !this.state.message) {
      return false;
    }

    axios({
      url: "https://us-central1-global-insurance-llc.cloudfunctions.net/contactUs",
      method: "POST",
      params: {
        name: this.state.name,
        email: this.state.email,
        subject: this.state.subject,
        message: this.state.message,
      }
    }).then(() => {
          sweetalert.fire("Success","Message Sent.","success");
        }).catch(() => {
          sweetalert.fire("Error","We were not able to send you message. Please try again later.","error");
    })
  };

  textChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

render() {
  return (

    <div >
      <div className="r-get-form">
        <form action="#" onSubmit={this.sendEmail}>
          <div className="clearfix">
            <div className="form-group"><input type="text" name={"name"} onChange={this.textChange} placeholder="Full Name"/></div>
            <div className="form-group"><input type="email" name={"email"} onChange={this.textChange} placeholder="Email Address"/></div>
          </div>
          <div className="form-group"><input type="text" name={"subject"} onChange={this.textChange} placeholder="Subject"/></div>
          <div className="form-group">
            <textarea onChange={this.textChange} placeholder="Message" name={"message"}/>
          </div>
          <div className="text-center">
            <button className="btn btn-full">SEND MESSAGE NOW</button>
          </div>
        </form>
      </div>
    </div>

  )
}
}
export default FormContactUs;

///////creamos un metodo y conectamos nuestro firebase
import firebase from 'firebase'
var config = {
  ////////// demo //////////
  // apiKey: "AIzaSyBe8UsQ-eCipUZ6R0ebf3H7Lag1Dl4sygo",
  // authDomain: "viksoftwareant.firebaseapp.com",
  // databaseURL: "https://viksoftwareant.firebaseio.com",
  // projectId: "viksoftwareant",
  // storageBucket: "viksoftwareant.appspot.com",
  // messagingSenderId: "32819040892",
  // appId: "1:32819040892:web:89d52548f4020544"

  ////////// Original //////////
    apiKey: "AIzaSyBfpKTGyuhRHjHoAq8VdH8UsLA0YE0QcRI",
    authDomain: "global-insurance-llc.firebaseapp.com",
    databaseURL: "https://global-insurance-llc.firebaseio.com",
    projectId: "global-insurance-llc",
    storageBucket: "global-insurance-llc.appspot.com",
    messagingSenderId: "798948350004",
    appId: "1:798948350004:web:e9eb52f1291c1fba"

};
firebase.initializeApp(config);

export default firebase;

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert2'


class FormHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      nameBuyClient: "",
      vinBuyClient: "",
      typeBuyClient: "",
      covergeBuyClient: "",
      startDBuyClient: "",
    }
    this.changeManagement = this.changeManagement.bind(this);
    // this.changeDateManagment = this.changeDateManagment.bind(this);
  }
  // changeDateManagment(dateEvent){
  //   const [startDate, setStartDate] = this.useState(new Date());
  // }
  changeManagement(event) {
      const name = event.target.name;
      this.setState({
        [name]: event.target.value
      });
    }

  insuranceButton = () => {
        var countValidation = 5

        if(this.state.nameBuyClient){
          countValidation = countValidation - 1
        }
        else{
          swal.fire({
            title: 'Enter your FULL NAME',
            text: 'to continue',
            type: 'error',
            confirmButtonText: 'Cool'
          })
          //alert("Enter your FULL NAME to continue")
        }



        if(this.state.typeBuyClient){
          countValidation = countValidation - 1
        }
        else{
          swal.fire({
            title: 'Enter your TYPE',
            text: 'to continue',
            type: 'error',
            confirmButtonText: 'Cool'
          })
          //alert("Enter your TYPE to continue")
        }

        if(this.state.covergeBuyClient){
          countValidation = countValidation - 1
        }
        else{
          swal.fire({
            title: 'Enter your COVERGE',
            text: 'to continue',
            type: 'error',
            confirmButtonText: 'Cool'
          })
          //alert("Enter your COVERGE to continue")
        }

        var startDBuyClientparts = document.getElementById("inputDateForm");
        if(startDBuyClientparts.value){
          countValidation = countValidation - 1
        }
        else{
          swal.fire({
            title: 'Enter your START DATE',
            text: 'to continue',
            type: 'error',
            confirmButtonText: 'Cool'
          })
          //alert("Enter your START DATE to continue")
        }

        if(this.state.vinBuyClient){
          axios.get("https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/" + this.state.vinBuyClient + "?format=json&model").then(res => {
            res.data.Results.forEach((doc) => {
            //  console.log(doc.ErrorCode)
              if(doc.ErrorCode === "0"){
                countValidation = countValidation - 1
                if(countValidation === 0){
                  this.props.history.push({pathname:"/insurance", state: {
                                                                          nameClient: this.state.nameBuyClient,
                                                                          vinClient: this.state.vinBuyClient,
                                                                          typeClient: this.state.typeBuyClient,
                                                                          covergeClient: this.state.covergeBuyClient,
                                                                          startDClient: startDBuyClientparts.value,
                                                                          }
                                          });
                }

              }else{
                swal.fire({
                  title: 'Invalid VIN',
                  text: 'check again',
                  type: 'error',
                  confirmButtonText: 'Cool'
                })
                //alert("Invalid VIN, check again")
              }
            })
          })
        }
        else{
          swal.fire({
            title: 'Enter your VIN NUMBER',
            text: 'to continue',
            type: 'error',
            confirmButtonText: 'Cool'
          })
          //alert("Enter your VIN NUMBER to continue")
        }


  }

render() {
  return (

      <form action="#" onSubmit={e => e.preventDefault()}>
          <div className="form-title form-title-large">
              <h2>CAR OR TRUCK<b> INSURANCE</b></h2>
          </div>

          <div className="row row-inputs">
            <div className="col-sm-12">
                <div className="form-group has-icon has-label">
                    <label>Full Name</label>
                    <input name="nameBuyClient" required className="form-control" placeholder="Your full name" type="text" value={this.state.nameBuyClient} onChange={this.changeManagement}/>
                    <span className="form-control-icon"><i className="fa fa-map-marker"></i></span>
                </div>
            </div>
          </div>
          <div className="row row-inputs">
            <div className="col-sm-12">
                <div className="form-group has-icon has-label">
                    <label>VIN NUMBER</label>
                    <input name="vinBuyClient" required className="form-control" placeholder="Your VIN Number" type="text" value={this.state.vinBuyClient} onChange={this.changeManagement}/>
                    <span className="form-control-icon"><i className="fa fa-map-marker"></i></span>
                </div>
            </div>
          </div>
          <div className="row row-inputs">
            <div className="col-sm-6">
                <div className="form-group has-icon has-label">
                    <label>Type</label>
                    <select name="typeBuyClient" required className="form-control" form="" value={this.state.typeBuyClient} onChange={this.changeManagement}>
                      <option value="" disabled>Select type</option>
                      <option value="Car">Car Insurance</option>
                      {/*<option value="Truck">Truck Insurance</option>*/}
                    </select>
                    <span className="form-control-icon"><i className="fa fa-map-marker"></i></span>
                </div>
            </div>

            <div className="col-sm-6">
                <div className="form-group has-icon has-label">
                    <label>Coverge</label>
                    <select name="covergeBuyClient" required className="form-control" form="" value={this.state.covergeBuyClient} onChange={this.changeManagement}>
                      <option value="" disabled>Select days</option>
                      <option value="1">1 day</option>
                      <option value="2">2 day</option>
                      <option value="3">3 day</option>
                      <option value="5">5 day</option>
                      <option value="10">10 day</option>
                    </select>
                    <span className="form-control-icon"><i className="fa fa-map-marker"></i></span>
                </div>
            </div>
          </div>
          <div className="row row-inputs">
            <div className="col-sm-6">
              <div className="form-group has-icon has-label label" id="datetimepicker2" data-target-input="nearest">
                <label>Start Date</label>
                <input id="inputDateForm" name="startDBuyClient" className="form-control datepicker" data-toggle="datetimepicker" data-target="#datetimepicker2" placeholder="dd/mm/yyyy" />
                <span className="form-control-icon"><i className="fa fa-calendar"></i></span>
              </div>
            </div>

            {/*<div className="col-sm-8">
                <div className="form-group has-icon has-label label" id="datetimepicker2" data-target-input="nearest" >
                    <label>Start Date</label>
                    <input id="inputDateForm" type="text" className="form-control datepicker" data-toggle="datetimepicker" data-target="#datetimepicker2" value="" onChange={this.changeManagement} placeholder="dd/mm/yyyy"/>
                    <span className="form-control-icon"><i className="fa fa-calendar"></i></span>
                </div>
            </div>
            <div className="col-sm-8">
                <div className="form-group has-icon has-label label" id="datetimepicker4" data-target-input="nearest">
                    <label>End Date</label>
                    <input type="text" className="form-control datepicker" data-toggle="datetimepicker" data-target="#datetimepicker4" placeholder="dd/mm/yyyy"/>
                    <span className="form-control-icon"><i className="fa fa-calendar"></i></span>
                </div>
            </div>*/}
          </div>
          <div className="form-footer">
            <div className="inner clearfix">
              { !this.state.user ?
                <Link to="/login">
                  <i className="fa fa-plus-circle"></i>Login / Register
                </Link>
              :
              <Link to="/login">
                <i className="fa fa-plus-circle"></i>Login / Register
              </Link>
              }
              <button type="submit" className="btn m-auto d-block btn-full" onClick={this.insuranceButton}>Next</button>
            </div>
          </div>
      </form>
  )
}
}
export default FormHeader;

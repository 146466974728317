import React, { Component } from 'react';

import firebase from "../js/firebase";
import moment from 'moment';
import axios from 'axios';
import swal from 'sweetalert2'

import FooterShortPage from './FooterPage';
import HeaderShortPage from './HeaderShortPage';

class InsuranceQuotePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      //////estado del usuario
      user: null,
      userName: null,
      /////datos de usuario
      email: "",
      password: "",
      passwordConf: "",
      converage: "coverage/clients",
      nameBuyClient: this.props.location.state.nameClient,
      vinBuyClient: this.props.location.state.vinClient,
      typeBuyClient: this.props.location.state.typeClient,
        ///startDClient
      covergeBuyClient: this.props.location.state.covergeClient + " days",
      startDBuyClient: moment(this.props.location.state.startDClient).add('hours', (1)).format('L'),
      endDBuyClient: moment(this.props.location.state.endDClient).add('hours', (1)).format('L'),
      dateEndtBuy: moment(this.props.location.state.startDClient).add('hours', (this.props.location.state.covergeClient * 24)).format('L'),
      priceEndBuy: 0,
      converageName: "",
      converageNotes: "",
      converagePrice: "",
      clientAnonymous: false,
      clientUID: "",
      clientName: "",
      clientEmail: "",
      carMakeBuy: "",
      carModelYearBuy: "",
      carModelBuy:"",
      carManufacturerBuy: "",
      carPlantCountryBuy: "",
      carPlantStateBuy: "",
      carVINBuy: "",
      carVehicleTypeBuy: "",
      carErrorTextBuy: "",
    };
  }

  componentDidMount(){
    axios.get("https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/" + this.state.vinBuyClient + "?format=json&model").then(res => {
      res.data.Results.forEach((doc) => {
        this.setState({carMakeBuy: doc.Make})
        this.setState({carModelYearBuy: doc.ModelYear})
        this.setState({carModelBuy: doc.Model})
        this.setState({carManufacturerBuy: doc.Manufacturer})
        this.setState({carPlantCountryBuy: doc.PlantCountry})
        this.setState({carPlantStateBuy: doc.PlantState})
        this.setState({carVINBuy: doc.VIN})
        this.setState({carVehicleTypeBuy: doc.VehicleType})
        this.setState({carErrorTextBuy: doc.ErrorText})
      })
    })

    //datos de converage
    var priceEndBuyVar = 0
    firebase.firestore().doc(this.state.converage).get().then((documentSnapshot) => {
          let data = documentSnapshot.data();
          priceEndBuyVar = "$ " + data.price * this.props.location.state.covergeClient + " DLL"
          this.setState({priceEndBuy: priceEndBuyVar})
          this.setState({converageName: data.name})
          this.setState({converageNotes: data.notes})
          this.setState({converagePrice: data.price})
          this.createPaypalButtons(this.props.location.state.covergeClient);
    });

    ////usuario Cliente datos
     firebase.auth().onAuthStateChanged((user) => {
      if (user) {
          if(user.email){
          var uid = user.uid;
          firebase.firestore().doc("clients/" + uid).get().then((documentSnapshot) => {
            let data = documentSnapshot.data();
            this.setState({clientUID: data.id})
            this.setState({userName: data.name})
            this.setState({email: data.email})
          });
        }
        else{
          //////console.log(user.uid)
        }
      }
    });
  }

  createPaypalButtons = (amount) => {
      window.paypal.Buttons({
          style: {
              shape: 'rect',
              color: 'blue',
              layout: 'vertical',
              label: 'paypal',
          },
          createOrder: function(data, actions) {
              return actions.order.create({
                  shipping_preference: "NO_SHIPPING",
                  purchase_units: [{
                      amount: {
                          value: amount
                      }
                  }]
              });
          },
          onApprove: function(data, actions) {
              return actions.order.capture().then(function(details) {
                  alert('Transaction completed by ' + details.payer.name.given_name + '!');
              });
          }
      }).render('#paypal');
  };

signUp = () => {

  if( this.state.passwordConf ===  this.state.password){
    firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then(user => {
        ///// jalar los datos
        var typeConverage = "";
        if(this.state.typeBuyClient === "Car"){
          typeConverage = "car-insurance"
        }
        else {
          typeConverage = "truck-insurance"
        }
        var idUser = user.user.uid;
        var emailUser = user.user.email;
        var converageUser = this.props.location.state.covergeClient;
        var nameClient = this.state.nameBuyClient;
        var convName= this.state.converageName;
        var convNotes= this.state.converageNotes;
        var converageQuantity = this.props.location.state.covergeClient;

        var convPrice= this.state.converagePrice;
        var dateFrom = this.state.startDBuyClient;
        var dateTo = this.statedateEndtBuy;
        var carMake = this.state.carMakeBuy;
        var carModel = this.state.carModelBuy;
        var totalPrice = this.state.priceEndBuy;
        var carState = this.state.carPlantStateBuy;
        var carVIN =this.state.carVINBuy;
        var carYear =this.state.carModelYearBuy;
        const id = firebase.firestore().collection(typeConverage).doc();
        var idConverage = id.id
        //// cadena cliente
        firebase.firestore().collection("clients").doc(idUser)
        .set({
            //string
            type: '',
            //string
            phone: '',
            //timestamp
            timestamp: moment().toDate(),
            //string
            id: idUser,
            //string
            name: this.state.nameBuyClient,
            //string
            email: emailUser,
            //string
            thumb: 'https://firebasestorage.googleapis.com/v0/b/global-insurance-llc.appspot.com/o/public%2Fuser-transparent.png?alt=media&token=08322061-d2d5-423a-9bde-e4abaed22a06',
            //string
            rfc: '',
            //string
            gender: '',
            //string
            dob: '',
            //string
            street: '',
            //string
            number: '',
            //string
            city: '',
            //string
            colony: '',
            //string
            town: '',
            //string
            state: '',
            //string
            zip:'',
            //string
            country: '',
            //string
            notes: '',
          })
          //// Mensaje de aprovacion limpiar formulario
          .then(function(docRef) {
            //// cadena a guardar poliza
            firebase.firestore().collection(typeConverage).doc(idConverage)
            .set({
                id: idConverage,
                paid: false,
                //reference
                clients: firebase.firestore().doc('clients/'+idUser),
                //string
                clientName: nameClient,
                //string
                coverage: firebase.firestore().doc(this.state.converage),
                converageName: convName,
                converageNotes: convNotes,
                converagePrice: convPrice,
                days: converageUser,
                extra: false,
                from: moment(dateFrom).toDate(),
                to: moment(dateTo).toDate(),
                insuranceID: "-",
                make: carMake,
                model: carModel,
                recept: null,
                state: null,
                //timestamp
                timestamp: moment().toDate(),
                total: totalPrice,
                usage: "Personal Use",
                user: firebase.firestore().doc('clients/'+idUser),
                userName: nameClient,
                vehicleState: carState,
                vin: carVIN,
                year: carYear,
              })

              //// Mensaje de aprovacion limpiar formulario
              .then(function(docRef) {
                console.log(docRef)
                const stripe = window["Stripe"]("pk_test_6V65SRv5JlgstRkTSxKRbiea00qCDIz5Qn");
                const quantityD = parseInt(converageQuantity)
                stripe.redirectToCheckout({
                  items: [{sku: 'sku_Fpm1k7yVDGsuex', quantity: quantityD}],
                   clientReferenceId: idConverage,
                   customerEmail: emailUser,
                   successUrl: 'https://globalinsurancebp.com/success',
                   cancelUrl: 'https://globalinsurancebp.com/insurance',
                }).then(function (result) {
                  //////console.log("demo " + result);
                });
              })
          })
          .catch(function(error) {
            console.error("Error adding document: ", error);
          });
      })

      .catch(error => {
        //////console.log(error)
        if( error.code === "auth/invalid-email" ){
          swal.fire({
            title: 'Invalid Email',
            text: 'to continue',
            type: 'error',
            confirmButtonText: 'Cool'
          })
        }
        if( error.code === "auth/email-already-in-use" ){
          swal.fire({
            title: 'The email address is already in use',
            text: 'to continue',
            type: 'error',
            confirmButtonText: 'Cool'
          })
        }
        if( error.code === "auth/weak-password" ){
          swal.fire({
            title: 'The password must be at least 6 characters',
            text: 'to continue',
            type: 'error',
            confirmButtonText: 'Cool'
          })
        }
      })
  }
  else{
    swal.fire({
      title: 'Password does not match when confirming',
      text: 'to continue',
      type: 'error',
      confirmButtonText: 'Cool'
    })
  }

}

buyNow = () => {
        var typeConverage = "";
        if(this.state.typeBuyClient === "Car"){
          typeConverage = "car-insurance"
        }
        else {
          typeConverage = "truck-insurance"
        }
        ///// jalar los datos
        var idUser = this.state.clientUID;
        var emailUser = this.state.email;
        var nameUser = this.state.userName;
        var converageUser = this.state.nameBuyClient;
        var converageQuantity = this.props.location.state.covergeClient;

        var nameClient = this.state.nameBuyClient;
        var convName= this.state.converageName;
        var convNotes= this.state.converageNotes;
        var convPrice= this.state.converagePrice;
        var dateFrom = this.state.startDBuyClient;
        var dateTo = this.statedateEndtBuy;
        var carMake = this.state.carMakeBuy;
        var carModel = this.state.carModelBuy;
        var totalPrice = this.state.priceEndBuy;
        var carState = this.state.carPlantStateBuy;
        var carVIN =this.state.carVINBuy;
        var carYear =this.state.carModelYearBuy;
        //// cadena a guardar poliza
        const id = firebase.firestore().collection(typeConverage).doc();
        var idConverage = id.id

        firebase.firestore().collection(typeConverage).doc(idConverage)
            .set({
                id: idConverage,
                paid: false,
                //reference
                clients: firebase.firestore().doc('clients/'+idUser),
                //string
                clientName: nameClient,
                //string
                coverage: firebase.firestore().doc(this.state.converage),
                converageName: convName,
                converageNotes: convNotes,
                converagePrice: convPrice,
                days: converageUser,
                extra: false,
                from: moment(dateFrom).toDate(),
                to: moment(dateTo).toDate(),
                insuranceID: "-",
                make: carMake,
                model: carModel,
                recept: null,
                state: null,
                //timestamp
                timestamp: moment().toDate(),
                total: totalPrice,
                usage: "Personal Use",
                user: firebase.firestore().doc('clients/'+idUser),
                userName: nameUser,
                vehicleState: carState,
                vin: carVIN,
                year: carYear,
              })
              //// Mensaje de aprovacion limpiar formulario
              .then(function(docRef) {
                console.log()
                const stripe = window["Stripe"]("pk_test_6V65SRv5JlgstRkTSxKRbiea00qCDIz5Qn");
                const quantityD = parseInt(converageQuantity)
                stripe.redirectToCheckout({
                    items: [{sku: 'sku_Fpm1k7yVDGsuex', quantity: quantityD}],
                   clientReferenceId: idConverage,
                   customerEmail: emailUser,
                   successUrl: 'https://globalinsurancebp.com/success',
                   cancelUrl: 'https://globalinsurancebp.com/insurance',
                }).then(function (result) {

                });
              })
}

render() {
  return (
      <div className="r-wrapper">
        <HeaderShortPage/>
        <section id="r-login-register">
          <div className="r-login-register">
            <div className="container clearfix">
              <div className="row clearfix">
                <div className="col-xl-12 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <h1><b>CHECK YOUR</b></h1>
                  <br/>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div className="r-auth-head">
                    <h2><b>VEHICLE</b> DATA</h2>
                    <br/>
                  </div>
                  <div className="row row-inputs">
                    <div className="col-sm-12">
                      <div className="form-group has-icon has-label">
                        <h5>VIN number</h5>
                        <h6>{this.state.carVINBuy}</h6>
                        <span className="form-control-icon"><i className="fa fa-map-marker"></i></span>
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <div className="form-group has-icon has-label">
                        <label>Model Year</label>
                        <h6>{this.state.carModelYearBuy}</h6>
                        <span className="form-control-icon"><i className="fa fa-calendar"></i></span>
                      </div>
                    </div>
                    <div className="col-sm-7">
                      <div className="form-group has-icon has-label">
                        <label>Make</label>
                        <h6>{this.state.carMakeBuy}</h6>
                        <span className="form-control-icon"><i className="fa fa-calendar"></i></span>
                      </div>
                    </div>

                    <div className="col-sm-5">
                      <div className="form-group has-icon has-label">
                        <label>Model</label>
                        <h6>{this.state.carModelBuy}</h6>
                        <span className="form-control-icon"><i className="fa fa-calendar"></i></span>
                      </div>
                    </div>
                    <div className="col-sm-7">
                      <div className="form-group has-icon has-label">
                        <label>Manufacturer</label>
                        <h6>{this.state.carManufacturerBuy}</h6>
                        <span className="form-control-icon"><i className="fa fa-calendar"></i></span>
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <div className="form-group has-icon has-label">
                        <label>State</label>
                        <h6>{this.state.carPlantStateBuy}</h6>
                        <span className="form-control-icon"><i className="fa fa-calendar"></i></span>
                      </div>
                    </div>
                    <div className="col-sm-7">
                      <div className="form-group has-icon has-label">
                        <label>Country</label>
                        <h6>{this.state.carPlantCountryBuy}</h6>
                        <span className="form-control-icon"><i className="fa fa-calendar"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
               <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                 <div className="r-auth-head">
                   <h2><b>INSURANCE</b> DATA</h2>
                   <br/>
                 </div>
                 <div className="row row-inputs">
                   <div className="col-sm-12">
                     <div className="form-group has-icon has-label">
                       <label>Full Name</label>
                       <h6>{this.state.nameBuyClient}</h6>
                       <span className="form-control-icon"><i className="fa fa-map-marker"></i></span>
                     </div>
                   </div>
                 </div>
                 <div className="row row-inputs">
                   <div className="col-sm-6">
                     <div className="form-group has-icon has-label">
                       <label>Type</label>
                       <h6>{this.state.typeBuyClient}</h6>
                       <span className="form-control-icon"><i className="fa fa-map-marker"></i></span>
                     </div>
                   </div>
                   <div className="col-sm-6">
                     <div className="form-group has-icon has-label">
                       <label>Coverge</label>
                       <h6>{this.state.covergeBuyClient}</h6>
                       <span className="form-control-icon"><i className="fa fa-map-marker"></i></span>
                     </div>
                   </div>
                 </div>
                 <div className="row row-inputs">
                   <div className="col-sm-6">
                     <div className="form-group has-icon has-label">
                       <label>Start Date</label>
                       <h6>{this.state.startDBuyClient}</h6>
                       <span className="form-control-icon"><i className="fa fa-calendar"></i></span>
                     </div>
                   </div>
                   <div className="col-sm-6">
                      <div className="form-group has-icon has-label">
                        <label>End Date</label>
                        <h6>{this.state.dateEndtBuy}</h6>
                        <span className="form-control-icon"><i className="fa fa-calendar"></i></span>
                      </div>
                   </div>
                </div>
                <div className="row">
                 <div className="col-sm-12">
                   <div className="form-group has-icon has-label">
                     <label>Your payment</label>
                     <h4>{this.state.priceEndBuy}</h4>
                     <span className="form-control-icon"><i className="fa fa-calendar"></i></span>
                   </div>
                 </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className="r-auth-head">
                  <h2><b>BUY</b> NOW</h2>
                </div>
                { !this.state.userName ?
                  <div className="r-auth-head">
                    <br/>
                    <span>It is important that you add an email and a password to see your documents in the future</span>
                    <div className="form-group">
                      <input type="email" required className="form-control" onChange = {event => this.setState({email: event.target.value})} placeholder="Email Address"/>
                    </div>
                    <div className="form-group">
                      <input type="password" required className="form-control" onChange={event => this.setState({passwordConf: event.target.value})} placeholder="Password"/>
                    </div>
                    <div className="form-group">
                      <input type="password" required className="form-control" onChange={event => this.setState({password: event.target.value})} placeholder="Confirm Password"/>
                    </div>
                    <br/>
                    {/*<button type="submit" className="btn m-auto d-block btn-full" onClick={this.signUp}>BUY NOW</button>*/}
                    <br/>
                  </div>
                :
                <div className="r-auth-head">
                  <br/>
                  <span>Buy now and drive with peace of mind</span>
                  <br/>
                  <br/>
                  {/*<button type="submit" className="btn m-auto d-block btn-full" onClick={this.buyNow}>BUY NOW</button>*/}
                  <br/>
                </div>
                }
                <div id={"paypal"}/>

              </div>

            </div>
          </div>
        </div>
      </section>
      <FooterShortPage/>
    </div>
  )
}
}
export default InsuranceQuotePage;

import React, { Component } from 'react';

import FormContactUs from '../forms/FormContactUs';

import iconHappyCustomer from '../assets/images/icon-happy-customer.png';
import iconCarsCount from '../assets/images/icon-cars-count.png';
import iconCarCenter from '../assets/images/icon-car-center.png';

class ContactPage extends Component {

render() {
  return (
    <div>
    <section id="contact">
          <div className="r-get-in-touch r-get-in-touch-dark">
            <div className="container">
              <div className="r-get-header">
                <span>CONTACT US NOW</span>
                <h2>Keep <b>In Touch</b></h2>
              </div>
              <FormContactUs/>
              <div className="r-counter-section r-counter-with-bg r-counter-transparent-bg mt-100  mb-0">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-4 col-md-4 col-sm-6">
                              <div className="r-counter-box">
                                  <div className="r-counter-icon">
                                      <img src={iconCarCenter} alt="" className="img-fluid"/>
                                  </div>
                                  <div className="r-counts" data-count="12">
                                      {/*<!-- 47.250 -->*/}
                                      <span className="r-count">0</span>
                                  </div>
                                  <span className="r-count-title"> YEARS OF EXPERIENCE </span>
                              </div>
                          </div>

                          <div className="col-lg-4 col-md-4 col-sm-6">
                              <div className="r-counter-box">
                                  <div className="r-counter-icon">
                                      <img src={iconHappyCustomer} alt="" className="img-fluid"/>
                                  </div>
                                  <div className="r-counts" data-count="1533">
                                  {/*<!-- 1.172.700 -->*/}
                                    <span className="r-count">0</span>
                                  </div>
                                  <span className="r-count-title"> HAPPY CUSTOMERS </span>
                              </div>
                          </div>

                          <div className="col-lg-4 col-md-4 col-sm-6">
                              <div className="r-counter-box">
                                  <div className="r-counter-icon">
                                      <img src={iconCarsCount} alt="" className="img-fluid"/>
                                  </div>
                                  <div className="r-counts" data-count="2543">
                                    {/*<!-- 2.450 -->*/}
                                    <span className="r-count">0</span>
                                  </div>
                                  <span className="r-count-title"> CAR INSURANCES </span>
                              </div>
                          </div>

                        {/*  <div className="col-lg-3 col-md-4 col-sm-6">
                              <div className="r-counter-box">
                                  <div className="r-counter-icon">
                                      <img src={iconTotalKm} alt="" className="img-fluid"/>
                                  </div>
                                  <div className="r-counts" data-count="834">*/}
                                    {/*<!-- 1.211.100 -->*/}
                                    {/*  <span className="r-count">0</span>
                                  </div>
                                  <span className="r-count-title"> TRUCK INSURANCES </span>
                              </div>
                          </div>*/}
                      </div>
                  </div>
              </div>

            </div>
          </div>
        </section>
    </div>
  )
}
}
export default ContactPage;

import React from 'react';
import FooterShortPage from './FooterPage';
import HeaderShortPage from './HeaderShortPage';

const Terms = () => {
	return (
		<div className="r-wrapper">
			<HeaderShortPage/>
			<section id="terms">
				<div className="r-login-register">
					<div className="container clearfix">
						<div className="row clearfix">
							<div className="r-auth-head">
								<h2><b>TERMS </b>OF SERVICE</h2>
								<br/>
							</div>

							<div className="row row-inputs">
								<div className="col-sm-12">
									<div className="form-group has-icon has-label">
										<label>Blue Point Global Insurance LLC. Point Terms of Use</label>
										<label>
											These globalinsurancebp.com Terms of Use Point Out the terms and conditions relating to your use of globalinsurancebp.com and provide additional information about Blue Point Global Insurance Automobile Insurance Company. These Terms of Use are applicable to your use of this site regardless of how you accessed it. If you do not wish to be bound by these Terms of Use, please discontinue using and accessing this site immediately.
											Privacy Policy
											To receive information about Blue Point Global Insurance LLC Privacy Policy, please visit our Notice of Privacy Policy.
											Your Use of This Site
											You may not use this site to engage in any illegal activity. You may not use this site to engage in conduct which is defamatory, libelous, threatening or harassing or that infringes on a third party's intellectual property or other proprietary rights. In the event you engage in activity that is illegal, defamatory, libelous, threatening, or harassing, Blue Point Global Insurance LLC reserves the right to disclose any information you provide through the use of this site. You agree that any information you provide through this site will be truthful and accurate. Blue Point Global Insurance LLC reserves the right to require a customer change their User ID or answers to security questions if they are deemed inappropriate.
											Emails to Blue Point Global Insurance
											The Blue Point Global Insurance LLC email system is a private intended only for the purposes authorized by the LLC. You are not authorized to send numerous unsolicited commercial email messages to any email addresses @globalinsurancebp.com. Blue Point Global Insurance LLC reserves the right to take actions as may be necessary to prevent misuse of its email system by unauthorized parties.
											Disclaimer of Warranties
											Any information, services, materials, software, calculators or other items on this site are provided "as is" without any warranty of any kind, either expressed or implied, including without limitation the implied warranties of merchantability and fitness for a particular purpose. Blue Point Global Insurance LLC does not warrant that this site will be uninterrupted or error free. Any risk arising out of use or performance of the information, services, materials, software, calculators or other items remains entirely with you. Under no circumstances will Blue Point Global Insurance be liable for any damages whatsoever arising out of the use or performance of the information, services, materials, software, calculators or other items.
											Secured Areas/Passwords
											Some portions of this site are restricted and require authorization for access. Unauthorized use of or access to these areas is prohibited. Actual or attempted unauthorized use of or access to such areas may result in criminal and/or civil prosecution. Attempts to access such areas without authorization may be viewed, monitored and recorded and any information obtained may be given to law enforcement organizations in connection with any investigation or prosecution of possible criminal activity on this system. If you are not an authorized user of such areas or do not consent to continued monitoring, you should not attempt to access such areas.
										</label>
										<br/>
										<br/>
										If you are an authorized user of any restricted area, you are responsible to maintain the security/confidentiality of your password. DO NOT SHARE YOUR PASSWORD WITH ANYONE. Blue Point Global Insurance will not ask you for your password. If you know or suspect that your password has been compromised, change your password immediately. If you suspect any unauthorized activity related to your account, you should contact our offices. Blue Point Global Insurance LLC will not be responsible if you do not properly secure your password or if you choose to share your password with anyone else. In order to protect your personal information Blue Point Global Insurance encourages you to change your password every six months.
										<br/>
										<br/>
										<label>INSURANCE QUOTES/COVERAGES</label>
										<label>At this time, on-line quotes may not be available for all states or provinces, products, services or coverage selections. For additional information about products, services or coverage selections otherwise offered by Blue Point Global Insurance LLC, please contact one of our agents.
											All quotes generated by this site are estimates based upon the information you provided and are not a contract, binder, or agreement to extend insurance coverage. Any coverage descriptions provided on this site are general descriptions of available coverages and are not a statement of contract. To obtain coverage you must submit an application to Blue Point Global Insurance. All applications are subject to underwriting approval. Coverages and availability may vary by state or province; and additional minimum coverage limits may be available in your state. For additional information, please contact Blue Point Global Insurance LLC.</label>
										<br/>
										<br/>
										<label>ONLINE CLAIM SUBMISSIONS</label>
										<label>If you are a Blue Point Global Insurance customer, you may elect to report an insurance claim online where available. Your submission does not commit Blue Point Global Insurance to coverage for this loss. Information you submit regarding your insurance policy and the loss is subject to review and verification. Blue Point Global Insurance LLC reserves the right to request additional information prior to reaching a decision on the claim. A claim representative will be communicating with you regarding your claim. All policy provisions contained in your policy remain in effect. If you have any questions concerning the coverage afforded by your policy, please contact us.</label>
										<br/>
										<br/>
										<label>CHANGES TO THIS DOCUMENT</label>
										<br/>
										<label>The content of this site and of these Terms of Use are subject to change without prior notice.</label>
										<br/>
										<label>LANGUAGE NOTICE</label>
										<label> Blue Point Global Insurance policies, agreements, applications, forms and required notices are written in English. This document has been translated for your convenience. In the event of any difference in interpretation, the English language version controls.
											Contact Information
											Please contact us for additional information.</label>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</section>
			<FooterShortPage/>
		</div>
	);
};
export default Terms;

import React, { Component } from 'react';

import { Link } from "react-router-dom";
import FooterShortPage from './FooterPage';
import HeaderShortPage from './HeaderShortPage';
import firebase from "../js/firebase";

class ClientPage extends Component {


  constructor(props) {
    super(props);
    this.state = {
      //////estado del usuario
      user: null,
    };
  }
  componentDidMount(){
    let parent = this;
     firebase.auth().onAuthStateChanged(function(user) {
      if(!user){
        parent.props.history.push("/login");
      }
    });
  }


render() {
  return (
    <div>
    <div className="r-wrapper">
    <HeaderShortPage/>

    <section id="r-quote">

      <div className="form-footer">
        <div className="inner clearfix">
          <div className="r-sec-head r-accent-color r-sec-head-L">
            <span>Don't wait any longer</span>
            <h2>Drive with the  <b>peace</b> of mind of being insured</h2>
          </div>
          <Link to="/buyNow">
            <button type="submit" className="btn m-auto d-block btn-full">Buy insurance now</button>
          </Link>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <div className="r-quote r-quote-sub pt-0 r-quote-noafter">
        <div className="r-sec-head r-accent-color r-sec-head-L">
          <span>Your</span>
          <h2>List of <b>tinsurance</b> purchased</h2>
        </div>

        <div className="container">
          <div className="row">
              <div className="col-md-12" data-wow-delay="0.2s">
                  <div id="r-quote-carousel" className="carousel slide">
                    <div className="carousel-inner" role="listbox">
                      <div className="carousel-item">
                         <p/>
                      </div>
                      <div className="carousel-item active">
                        <p>You currently do not have purchased insurance</p>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </section>
      <FooterShortPage/>
    </div>
    </div>
  )
}
}
export default ClientPage;
